@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .card {
      @apply bg-white shadow-lg p-8 dark:bg-dark-base-200 dark:text-dark-content-200
  }
  .btn {
      @apply px-6 py-2 rounded-lg disabled:opacity-50
  }
  .btnPrimary{
    @apply btn text-white bg-blue-600 enabled:hover:bg-blue-900 disabled:cursor-default 
  }
  .btnSecondary{
    @apply btn text-black bg-primary-600 hover:bg-primary-400
  }
  .btnGray{
    @apply btn text-white bg-gray-500 hover:bg-gray-400
  }
  .prosAndConsListing{
    
  }
  .generic-link{
    @apply bg-gradient-to-r from-primary-500 to-primary-500 bg-growing-underline-from-c hover:text-gray-800 dark:hover:text-white
  }
  .generic-link-l{
    @apply bg-gradient-to-r from-primary-500 to-primary-500 bg-growing-underline-from-l hover:text-gray-800 dark:hover:text-white
  }
  /*#region bg-growing-underline*/
  .bg-growing-underline-from-l {
    background-size: 0% 0.2em;
    background-position: 0% 100%;
    @apply bg-no-repeat transition-all duration-200;
  }
  .bg-growing-underline-from-l:hover {
    background-size: 100% 0.2em;
  }
  .bg-growing-underline-from-l:focus {
    background-size: 100% 0.2em;
  }
  .bg-growing-underline-from-l-manual-selected{    
    background-size: 100% 0.2em;
  }
  /*#endregion*/
  /*#region bg-growing-underline*/
  .bg-growing-underline-from-c {
    background-size: 0% 0.2em;
    background-position: 50% 100%;
    @apply bg-no-repeat transition-all duration-200;
  }
  .bg-growing-underline-from-c:hover {
    background-size: 100% 0.2em;
  }
  .bg-growing-underline-from-c:focus {
    background-size: 100% 0.2em;
  }
  .bg-growing-underline-from-c-manual-selected{    
    background-size: 100% 0.2em;
  }
  /*#endregion*/
  .abs-center{
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
  }
}

html,
body ,
body > div:first-child,
div#__next {
height: 100%;
}
div#__next > div {
position:relative;
overflow: visible
}
body {
  margin: 0;
  padding: 0;
}

.my-rotate-y-180 {
  transform: rotateY(180deg);
}
.preserve-3d{
  transform-style: preserve-3d;
}
.perspective {
  perspective: 1000px;
}
.backface-hidden {
  backface-visibility: hidden;
}
.flickity-slider div:not(.is-selected) img {
    /* filter: blur(1px);
    -webkit-filter: blur(1px); */
}

/* .carousel:not(.noShadow) .flickity-slider div:not(.is-selected)::after  {
    background: rgba(2, 2, 2, 0.50);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */

.flickity-slider > div:not(.is-selected)::after  {
    background: rgba(2, 2, 2, 0.50);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: 50px !important;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
}

.carousel-cell {
    /* min-width: 400px; */
    margin-right: 0px;
    border-radius: 5px;
    text-align: center;
}

.grecaptcha-badge { visibility: hidden; }



.marquee-container > .marquee {
  --duration: 100s !important
}

.ct-fancy-bg{
  line-height:1 ; 
  color:transparent;    
  animation: highlight 6s linear infinite;
  background-color: none;
  background: linear-gradient(135deg, #800088 0%, #0069bb 25%, #800088 50%, #0069bb 75%, #800088 100%, #0069bb 125%);
  background-size: 200% 100%;
  background-position: 100% 0%;
  @apply text-transparent  group-hover:contrast-100 contrast-0 transition-all duration-1000
}
.ct-fancy-text{
  line-height:1 ; 
  color:transparent;    
  animation: highlight 6s linear infinite;
  background-color: none;
  background: linear-gradient(135deg, #800088 0%, #0069bb 25%, #800088 50%, #0069bb 75%, #800088 100%, #0069bb 125%);
  background-size: 200% 100%;
  background-position: 100% 0%;
  @apply text-transparent  group-hover:contrast-100 contrast-0 transition-all duration-1000 bg-clip-text
}


@keyframes highlight {
  to {
    background-position: 0% 0%;
  }
 }
